<template>
  <div
    v-if="$route.name === 'adminId' && store.isAdminStatus === 200"
    class="bt-control"
  >
    <div class="bt-control__input-area">
      <input
        ref="titleInput"
        v-model="commentValue"
        type="text"
        class="bt-control__input"
        @keydown.enter="dispatchComment()"
      >
      <IconSend
        class="bt-control__icon-send"
        :class="{'bt-control_active' : commentValue}"
        @click="dispatchComment()"
      />
    </div>
    <div
      class="bt-control__icon-delete"
      @click="isOpened = true"
    >
      <IconDelete />
    </div>
  </div>
  <DeleteReport
    v-if="isOpened"
    :is-opened="isOpened"
    @update:isOpened="isOpened = $event"
  />
</template>
<script>
import DeleteReport from '~/components/parts/DeleteReport.vue'
import { IconSend, IconDelete } from '~/components/icons/index'
import { useStore } from '~/store'
export default {
  name: 'ControlModal',
  components: {
    IconSend,
    IconDelete,
    DeleteReport
  },
  setup () {
    const store = useStore()
    return { store }
  },
  data () {
    return {
      commentValue: '',
      isOpened: false,
      pendingComment: 'Thank you for helping us improve our app! We really appreciate it from our community.Your report has been forwarded to development and it will take a bit of time to refine the details and calculate the award.',
      rejectedComment: 'Unfortunately your report has not been approved for some reason.Reason:',
      approvedComment: 'Your report passed the enter filter and delivered to DeNet team!We will consider it and make a decision on this report soon. You will find a message on reward here.',
      rewardedComment: 'Congratulations! You were rewarded with 10 TB for your bug report! You can use it in the DeNet account to store your files. Thanks for your interest to DeNet ecosystem! Continue to explore the ecosystem and help us to improve it!'
    }
  },
  mounted () {
    this.store.isAdmin()
  },
  methods: {
    dispatchComment () {
      if (this.commentValue !== '') {
        this.store.sendComment(this.commentValue)
        this.commentValue = ''
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~/assets/scss/parts/Control.scss";
</style>
