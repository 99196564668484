<template>
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :style="{cursor: 'pointer'}"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.711388 0.646447C0.90665 0.451184 1.22323 0.451184 1.41849 0.646447L14.3532 13.5811C14.5484 13.7764 14.5484 14.093 14.3532 14.2882C14.1579 14.4835 13.8413 14.4835 13.6461 14.2882L0.711388 1.35355C0.516126 1.15829 0.516126 0.841709 0.711388 0.646447Z"
      :fill="selectedColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.3534 0.646447C14.5486 0.841709 14.5486 1.15829 14.3534 1.35355L1.41869 14.2882C1.22343 14.4835 0.90685 14.4835 0.711588 14.2882C0.516326 14.093 0.516326 13.7764 0.711588 13.5811L13.6463 0.646447C13.8415 0.451184 14.1581 0.451184 14.3534 0.646447Z"
      :fill="selectedColor"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: { type: String, default: '' }
  },
  computed: {
    selectedColor () {
      if (this.color) {
        return this.color
      } else {
        return '#232323'
      }
    }
  }
}
</script>
