<template>
  <transition
    name="component-fade"
    mode="out-in"
  >
    <div
      v-if="show"
      class="bt-connect-wallet-auth"
    >
      <div class="bt-connect-wallet-auth__content">
        <BugTrackerLogo class="bt-connect-wallet-auth__img" />
        <span class="bt-connect-wallet-auth__title">Connect Wallet</span>
        <button
          v-for="wallet in walletList"
          :key="wallet"
          class="bt-connect-wallet-auth__btn"
          @click="wallet.func"
          :class="{'wallet-connecting' : connectedWallet}"
          :disabled="!wallet.enabled"
        >
          <component
            class="bt-connect-wallet-auth__btn-image"
            :is="wallet.icon" />
          <span>{{ wallet.title }}</span>
          <span
            v-if="wallet.title === 'DeNet Pass'"
            class="bt-connect-wallet-auth__soon-text">Soon</span>
          <span
            v-if="wallet.enabled"
            class="bt-connect-wallet-auth__btn-circle"
          />
        </button>
      </div>
    </div>
  </transition>
</template>
<script>
import { DefenderLogo, MetamaskLogo, BugTrackerLogo, WalletConnectLogo } from '~/components/icons/index'
import { useStore } from '../../store/index'
export default {
  name: 'ConnectWalletAuth',
  components: {
    DefenderLogo,
    MetamaskLogo,
    BugTrackerLogo,
    WalletConnectLogo
  },
  setup () {
    const store = useStore()
    return { store }
  },
  data () {
    return {
      show: false,
      walletList: {
        metamask: {
          title: 'MetaMask',
          provider: 'metamask',
          func: this.store.authMetamask,
          enabled: window.ethereum && window.ethereum.isMetaMask,
          icon: MetamaskLogo
        },
        walletConnect: {
          title: 'WalletConnect',
          provider: 'walletConnect',
          func: this.store.authWalletConnect,
          enabled: true,
          icon: WalletConnectLogo
        },
        deNetPass: {
          title: 'DeNet Pass',
          provider: false,
          enabled: false,
          icon: DefenderLogo
        }
      }
    }
  },
  mounted () {
    this.show = true
  }
}
</script>
<style lang="scss" scoped>
@import '~/assets/scss/parts/ConnectWalletAuth.scss';
</style>
