import { defineStore } from 'pinia'
import axios from 'axios'
import { apiRequest } from '@/helpers/utils'
import { ethers } from 'ethers'
import {
  openModal,
  watchAccountData,
  signMessage,
  disconnectWallet,
  UserRejectedRequestError
} from '../wallet-connect'
import router from '../router'
export const useStore = defineStore('store', {
  state: () => ({
    baseUrl: 'https://bugs.denet.pro/api/v0',
    reportModal: false,
    address: '',
    infoReport: {},
    getCommentUser: {},
    token: null,
    isAdminStatus: {},
    bugTrackerReportsList: {},
    reportAdded: false,
    fileSrcList: [],
    currentRouteName: 'User',
    filteredApps: [],
    filteredStatus: [],
    alertObj: {
      selectionAppAlert: false,
      contentAlert: false,
      subjectAlert: false
    },
    searchValue: '',
    formObj: {
      title: '',
      body: '',
      app: 'Select',
      type: 'bug',
      files: '',
      appVersion: '',
      os: ''
    }
  }),
  getters: {
    reportList () {
      let filteredList = []
      if (Array.isArray(this.bugTrackerReportsList)) {
        filteredList = this.bugTrackerReportsList.filter(item => item.type === this.formObj.type)
      }
      if (this.searchValue) {
        filteredList = filteredList.filter((item) =>
          item.body.toLowerCase().includes(this.searchValue.toLowerCase()) ||
          item.sender.toLowerCase().includes(this.searchValue.toLowerCase()) ||
          item.title.toLowerCase().includes(this.searchValue.toLowerCase())
        )
      } else if (this.filteredApps.length > 0 || this.filteredStatus.length > 0) {
        if (this.filteredApps.length !== 0 && this.filteredStatus.length !== 0) {
          filteredList = filteredList.filter(item =>
            this.filteredApps.some(filter => filter.toLowerCase().trim() === item.app.toLowerCase().replace('denet', '').trim()) &&
            this.filteredStatus.some(filter => filter.toLowerCase() === item.status.toLowerCase())
          )
        } else {
          filteredList = filteredList.filter(item =>
            this.filteredApps.some(filter => filter.toLowerCase().trim() === item.app.toLowerCase().replace('denet', '').trim()) ||
            this.filteredStatus.some(filter => filter.toLowerCase() === item.status.toLowerCase())
          )
        }
      }
      return filteredList
    },
    countReports () {
      if (!Array.isArray(this.bugTrackerReportsList)) {
        return 0
      }
      return this.bugTrackerReportsList.filter(item => item.type === this.formObj.type).length
    },
    renameApp: (state) => (value) => {
      return value ? value.toLowerCase().replace('denet', '').trim() : value
    },
    titleCount: (getters) => (value) => {
      return getters.reportList.filter(item => item.app.toLowerCase().replace('denet', '').trim() === value.toLowerCase()).length
    },
    statusCount: (getters) => (value) => {
      return getters.reportList.filter(item => item.status.toLowerCase() === value.toLowerCase()).length
    }
  },
  actions: {
    async apiDispatch (obj) {
      obj.sessionToken = this.token
      try {
        const res = await apiRequest(obj)
        return res.data
      } catch (e) {
        if (e.response.status === 401) {
          this.localStorageActions({ action: 'delete' })
          this.token = null
          router.push('/')
        } else if (e.response.status === 403) {
          router.push('/')
        }
      }
    },
    searchReport (value) {
      if (value) this.searchValue = value
      else this.searchValue = ''
    },
    localStorageActions (payload) {
      if (payload && payload.action === 'save') {
        localStorage.setItem('bugTracker', payload.obj)
      }
      if (payload && payload.action === 'check') {
        return localStorage.getItem('bugTracker')
      }
      if (payload && payload.action === 'delete') {
        localStorage.removeItem('bugTracker')
      }
    },
    sendReport () {
      const formData = new FormData()
      formData.append('title', this.formObj.title)
      formData.append('body', this.formObj.body)
      formData.append('app', 'DeNet ' + this.formObj.app)
      formData.append('type', this.formObj.type)
      const files = this.formObj.files
      for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i])
      }
      return axios({
        method: 'post',
        url: `${this.baseUrl}/my/report`,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        },
        data: formData
      })
        .then((res) => {
          if (res.status === 200) {
            this.getAllReports()
            this.reportAdded = true
            this.reportModal = false
            this.inputFile = ''
            this.formObj.title = ''
            this.formObj.body = this.reportBody
            this.formObj.app = 'Select'
          }
        })
    },
    getNonce (address) {
      return axios({
        method: 'get',
        url: `${this.baseUrl}/nonce?address=${address}`
      })
    },
    async checkToken () {
      const getSavedToken = await this.localStorageActions({ action: 'check' })
      const savedToken = JSON.parse(getSavedToken)
      if (savedToken && savedToken.token) {
        this.token = savedToken.token
        this.address = savedToken.userAddress
      } else {
        this.token = null
      }
    },
    async isAdmin () {
      try {
        const response = await axios.get(`${this.baseUrl}/adm/is_admin`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.token}`
          }
        })
        this.isAdminStatus = response.status
      } catch (error) {
        this.isAdminStatus = error.status
      }
    },
    getToken (data) {
      return axios({
        method: 'post',
        url: `${this.baseUrl}/login`,
        data: {
          message: data.message,
          address: data.address,
          nonce: data.nonce,
          signed_hash: data.signed_hash
        }
      })
    },
    async getReports () {
      const res = await this.apiDispatch({
        method: 'get',
        url: `${this.baseUrl}/my/reports`
      })
      this.bugTrackerReportsList = res
      this.bugTrackerReportsList.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    },
    async getAdminReports () {
      const res = await this.apiDispatch({
        method: 'get',
        url: `${this.baseUrl}/adm/reports`
      })
      this.bugTrackerReportsList = res
      this.bugTrackerReportsList.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    },
    async sentReward (valueReward) {
      const res = await this.apiDispatch({
        method: 'get',
        url: `${this.baseUrl}/adm/reward?report=${this.infoReport.id}&reward=${valueReward}`
      })
      if (res.success === 'report rewarded') {
        this.getOneReport(this.infoReport.id)
        this.getAllReports()
      }
    },
    async deleteComment (commentId) {
      await this.apiDispatch({
        method: 'get',
        url: `${this.baseUrl}/adm/comment/del?comment=${commentId}`
      })
      await this.getComment()
    },
    async getLoadReport (newStatus) {
      const res = await this.apiDispatch({
        method: 'get',
        url: `${this.baseUrl}/adm/status?report=${this.infoReport.id}&status=${newStatus}`
      })
      if (res.success === 'status updated') {
        this.getOneReport(this.infoReport.id)
        this.getAllReports()
      }
    },
    async getComment () {
      const res = await this.apiDispatch({
        method: 'get',
        url: `${this.baseUrl}/comments?report=${this.infoReport.id}`
      })
      this.getCommentUser = res.comments
    },
    async getOneReport (reportId) {
      const res = await this.apiDispatch({
        method: 'get',
        url: `${this.baseUrl}/my/report?report=${reportId}`
      })
      this.infoReport = res
      return res
    },
    async logout () {
      await this.apiDispatch({
        method: 'get',
        url: `${this.baseUrl}/my/logout`
      })
      this.localStorageActions({ action: 'delete' })
      this.token = null
      this.address = ''
      this.isAdminStatus = null
      this.bugTrackerReportsList = []
      router.push('/')
      disconnectWallet()
    },
    fileDownload (fileName) {
      this.apiDispatch({
        method: 'post',
        url: `${this.baseUrl}/my/download`,
        responseType: 'blob',
        data: {
          report_id: this.infoReport.id,
          user_address: this.infoReport.sender,
          filename: fileName
        }
      }).then(response => {
        const blob = new Blob([response])
        this.fileSrcList.push(window.URL.createObjectURL(blob))
      })
    },
    async sendComment (commentValue) {
      this.apiDispatch({
        method: 'post',
        url: `${this.baseUrl}/adm/comment`,
        data: {
          report_id: this.infoReport.id,
          message: commentValue
        }
      })
      await this.getComment()
    },
    async deleteReport () {
      await this.apiDispatch({
        method: 'get',
        url: `${this.baseUrl}/adm/report/del?report=${this.infoReport.id}`
      })
      await this.getAllReports()
    },
    async getAllReports () {
      await this.isAdmin()
      if (this.$state.isAdminStatus === 200 && this.$state.currentRouteName === 'Admin') {
        await this.getAdminReports()
      }
      if (this.$state.isAdminStatus !== 401 && this.$state.currentRouteName !== 'Admin') {
        await this.getReports()
      }
    },
    async authMetamask () {
      try {
        if (!window.ethereum) throw new Error('No crypto wallet found. Please install it.')
        await window.ethereum.request({ method: 'wallet_requestPermissions', params: [{ eth_accounts: {} }] })
        const userAddress = (await window.ethereum.request({ method: 'eth_requestAccounts' }))[0]
        const nonce = (await this.getNonce(userAddress)).data.nonce
        const message = `Welcome to DeNet BugTracker!\n\nClick to sign in and accept the BugTracker Terms of Service.\n\nThis request will not trigger a blockchain transaction or cost any gas fees.\n\nYour authentication status will reset after 30 days.\n\nAddress: ${userAddress}\n\nNonce: ${nonce}`
        const signature = await new ethers.providers.Web3Provider(window.ethereum).getSigner().signMessage(message)
        const data = { address: userAddress, nonce, message, signed_hash: signature }
        const getUserToken = await this.getToken(data)
        const saveData = { ...getUserToken.data, userAddress }
        this.localStorageActions({ action: 'save', obj: JSON.stringify(saveData) })
        this.token = saveData.token
        this.address = saveData.userAddress
        await this.checkToken()
      } catch (err) {
        this.connectedWallet = false
      }
    },
    async authWalletConnect () {
      await openModal()
      const saveUserAddress = async (userAddress) => {
        const isValidAddress = userAddress && userAddress.includes('0x')
        if (isValidAddress) {
          const nonce = (await this.getNonce(userAddress)).data.nonce
          const message = `Welcome to DeNet BugTracker!\n\nClick to sign in and accept the BugTracker Terms of Service.\n\nThis request will not trigger a blockchain transaction or cost any gas fees.\n\nYour authentication status will reset after 30 days.\n\nAddress: ${userAddress}\n\nNonce: ${nonce}`
          try {
            const signature = await signMessage({
              message: message
            })
            const data = { address: userAddress, nonce, message, signed_hash: signature }
            const getUserToken = await this.getToken(data)
            const saveData = { ...getUserToken.data, userAddress }
            this.localStorageActions({ action: 'save', obj: JSON.stringify(saveData) })
            this.token = saveData.token
            this.address = saveData.userAddress
            this.checkToken()
          } catch (error) {
            disconnectWallet()
            if (error instanceof UserRejectedRequestError) {
              console.log('Do something appropriate, such as display a message to the user or prompt them to try again')
            } else {
              console.log('Handle other types of errors as needed')
            }
          }
        }
      }
      watchAccountData(saveUserAddress)
    }
  }
})
