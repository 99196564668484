<template>
  <transition-group
    name="component-fade"
    mode="out-in"
  >
    <div
      v-if="show"
      class="bug-tracker"
    >
      <component :is="selectedComponent" />
    </div>
  </transition-group>
</template>

<script>
import ConnectWalletAuth from '~/components/parts/ConnectWalletAuth.vue'
import BugTrackerMain from '~/components/parts/BugTrackerMain.vue'
import { useStore } from '../store'
export default {
  name: 'ConnectWallet',
  components: {
    ConnectWalletAuth,
    BugTrackerMain
  },
  setup () {
    const store = useStore()
    return { store }
  },
  data () {
    return {
      show: false
    }
  },
  computed: {
    selectedComponent () {
      return this.store.token ? 'BugTrackerMain' : 'ConnectWalletAuth'
    }
  },
  async mounted () {
    this.show = true
  }
}
</script>

<style lang="scss" scoped>
.bug-tracker {
  user-select: none;
  height: 100%;
  width: 100%;
}
</style>
