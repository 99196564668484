<template>
  <router-view />
</template>

<script>
import { useStore } from './store/index'
export default {
  name: 'App',
  setup () {
    const store = useStore()
    return { store }
  },
  head () {
    return {
      title: 'DeNet Bug Tracker',
      meta: [
        { name: 'description', content: 'Bugtracker is a communication tool for users and developers to report bugs in the app and make suggestions for implementing and improving its functionality. Therefore, it is important that bugs reported by users are clearly and thoroughly described.' },
        { property: 'og:title', content: 'DeNet Bug Tracker' },
        { property: 'og:description', content: 'Bugtracker is a communication tool for users and developers to report bugs in the app and make suggestions for implementing and improving its functionality. Therefore, it is important that bugs reported by users are clearly and thoroughly described.' },
        { property: 'og:image', content: 'https://bugs.denet.pro/denet-bug-tracker-logo.png' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { property: 'twitter:title', content: 'DeNet Bug Tracker' },
        { property: 'twitter:description', content: 'Bugtracker is a communication tool for users and developers to report bugs in the app and make suggestions for implementing and improving its functionality. Therefore, it is important that bugs reported by users are clearly and thoroughly described.' },
        { property: 'twitter:image', content: 'https://bugs.denet.pro/denet-bug-tracker-logo.png' }
      ]
    }
  },
  mounted () {
    this.store.checkToken()
  }
}
</script>

<style lang="scss">
@import "src/assets/scss/fonts";
body {
  color: $colorDark;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  min-width: 1280px;
  @media (min-width: 1921px) {
    font-size: 0.8vw;
  }
}
#app {
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: hidden;
  &::-webkit-scrollbar {
    width: 8px;
    @media (min-width: 1921px) {
      width: .4vw;
    }
  }
    &::-webkit-scrollbar-track {
    padding: 5px;
    background-color: lighten(#babcc6, 20%);
  }
  &::-webkit-scrollbar-thumb {
    background-color: lighten(#babcc6, 10%);
    -webkit-border-radius: 7px;
  }
}
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-enter-from,
.component-fade-leave-to {
  opacity: 0;
}
</style>
