<template>
  <div class="delete-report">
    <div class="delete-report__inner-wrapper">
      <IconClose
        class="delete-report__icon-close"
        @click="closeModal"
      />
      <span
        v-if="store.formObj.type === 'bug'"
        class="delete-report__subtitle"
      >Remove bug?</span>
      <span v-else>Remove suggestion?</span>
      <div class="delete-report__wrap">
        <button
          class="delete-report__btn"
          @click="closeModal"
        >
          No
        </button>
        <button
          class="delete-report__btn delete-report__btn-done"
          @click="removeReport"
        >
          Yes
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { IconClose } from '~/components/icons/index'
import { useStore } from '~/store'

export default {
  components: { IconClose },
  props: {
    isOpened: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    const store = useStore()
    return { store }
  },
  mounted () {
    const $this = this
    $this.$nextTick(() => {
      document.body.addEventListener('click', function (e) {
        const arrayClass = ['delete-report']
        if (arrayClass.includes(e.target.classList.value)) {
          $this.closeModal()
        }
      })
    })
  },
  methods: {
    closeModal () {
      this.$emit('update:isOpened', false)
    },
    async removeReport () {
      await this.store.deleteReport()
      this.closeModal()
      const destination = this.$route.name === 'userId' ? '/' : '/admin'
      this.$router.push(destination)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~/assets/scss/parts/DeleteReport.scss";
</style>
