<template>
  <div class="bt-main">
    <div class="bt-main__wrapper">
      <Header />
      <div class="bt-main__content">
        <FilterReports class="bt-main__filter-reports" />
        <ReportList class="bt-main__reports" />
        <component :is="selectedModal" />
      </div>
    </div>
  </div>
</template>
<script>
import Header from '~/components/parts/Header.vue'
import FormReport from '~/components/parts/FormReport.vue'
import FilterReports from '~/components/parts/FilterReports.vue'
import ReportList from '~/components/parts/ReportList.vue'
import { useStore } from '../../store/index'
export default {
  name: 'BugTrackerMain',
  components: {
    FormReport,
    Header,
    FilterReports,
    ReportList
  },
  setup () {
    const store = useStore()
    return { store }
  },
  computed: {
    selectedModal () {
      return this.store.reportModal ? 'FormReport' : 'ReportAdded'
    }
  },
  mounted () {
    this.store.currentRouteName = this.$route.name
    this.store.getAllReports()
  }
}
</script>
<style scoped lang="scss">
@import "~/assets/scss/parts/BugTrackerMain.scss";
</style>
