<template>
  <div class="open-image">
    <div class="open-image__control">
      <span
        class="open-image__icon-close"
        @click="$emit('update:isOpen', false)"
      >&#8592;</span>
    </div>
    <div class="open-image__modal">
      <img
        id="source"
        class="open-image__image"
        :src="fileSrc"
      >
    </div>
  </div>
</template>
<script>

export default {
  props: ['isOpen', 'fileSrc'],
  mounted () {
    const $this = this
    $this.$nextTick(() => {
      document.body.addEventListener('click', function (e) {
        const arrayClass = ['open-image']
        if (arrayClass.includes(e.target.classList.value)) {
          $this.$emit('update:isOpen', false)
        }
      })
    })
  }
}
</script>
<style lang="scss" scoped>
.open-image{
  @include setPosition(fixed, 0, 0, 0, 0);
  @include flexBox(center, center);
  z-index: 150;
  &::before{
    content: "";
    @include setPosition(absolute, 0, 0, 0, 0);
    background-color: rgba(0,0,0,.85);
    z-index: -1;
  }
  &__icon-close{
    font-size: 45px;
    color: $colorWhite;
    cursor: pointer;
    z-index: 1111;
  }
  &__modal{
    position: relative;
    height: 80%;
    width: 80%;
    margin: auto;
    @include flexBox(center, center);
  }
  &__control{
    @include flexBox(center, flex-start);
    @include setPosition(absolute, 0, auto, auto, 20px);
    height: max-content;
    width: 100%;
  }
  &__image{
    max-height: 100%;
    max-width: 100%;
    min-width: 50%;
    height: auto;
    width: auto;
  }
  &__video{
    height: 100%;
    width: 100%;
  }
}
</style>
