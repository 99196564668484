<template>
  <div class="icon-loading">
    <div class="icon-loading__circle" />
    <div class="icon-loading__circle" />
    <div class="icon-loading__circle" />
  </div>
</template>
<style scoped lang="scss">
.icon-loading {
  display: flex;
  transition: opacity .3s ease-in;
  &__circle{
  border: solid 2px #ffffff;
  border-radius: 50%;
  margin: 2px;
  height: 15px;
  width: 15px;
  animation: jump .9s linear infinite;
  &:nth-child(2){
    animation-delay: .2s;
  }
  &:nth-child(3){
    animation-delay: .4s;
  }
  @media (min-width:1921px) {
    border: solid .1vw #ffffff;
    margin: .1vw;
    height: .75vw;
    width: .75vw;
  }
}
@keyframes jump {
  0%, 100% {
    transform: translateY(3px);
  }
  50% {
    transform: translateY(-8px);
    }
  }
}
</style>
