import { configureChains, createClient, disconnect, signMessage, mainnet, UserRejectedRequestError } from '@wagmi/core'
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/html'

const projectId = 'df003c3221043d45b392489ad935f43c'
export const { provider, chains } = configureChains([mainnet], [w3mProvider({ projectId })])
const wagmiClient = createClient({
  autoConnect: true,
  connectors: w3mConnectors({ chains, version: 1, projectId }),
  provider
})
export const ethereumClient = new EthereumClient(wagmiClient, chains)
export const web3Modal = new Web3Modal(
  {
    projectId,
    defaultChain: chains
  },
  ethereumClient
)

async function openModal () {
  await web3Modal.openModal()
}
async function disconnectWallet () {
  await disconnect()
}

function watchAccountData (saveAddress) {
  ethereumClient.watchAccount((account) => {
    saveAddress(account.address)
  })
}

export {
  openModal,
  disconnectWallet,
  watchAccountData,
  signMessage,
  UserRejectedRequestError
}
