<template>
  <div
    class="input-alerts"
    :class="[ {'input-alerts__textarea-content' : itemType === 'contentAlert'}, {'input-alerts__input-character' : ['ocAlert', 'versionAlert'].includes(itemType)}]"
  >
    <div
      class="input-alerts__error-icon"
      :class="{'input-alerts__error-select' : itemType === 'selectionAppAlert'}"
    >
      <span>!</span>
    </div>
    <div
      class="input-alerts__error-message input-alerts__subject-alerts"
      :class="{'input-alerts__select-alerts' : itemType === 'selectionAppAlert'}"
    >
      <span v-if="itemType === 'subjectAlert'">Fill Title</span>
      <span v-if="itemType === 'selectionAppAlert'">Select App</span>
      <span v-if="itemType === 'contentAlert'">Fill Content</span>
      <span v-if="itemType === 'versionAlert'">Fill Version</span>
      <span v-if="itemType === 'ocAlert'">Fill OC</span>
    </div>
    <div
      v-if="itemType === 'InputAlertForfiles'"
      class="input-alerts__error-message"
    >
      <span>Maximum file size 64 mb</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputAlerts',
  props: { itemType: { type: String, default: '' } }
}
</script>
<style lang="scss" scoped>
@import '~/assets/scss/parts/InputAlerts.scss';
</style>
