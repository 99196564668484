<template>
  <div class="bt-info-report">
    <div
      ref="contentModal"
      class="bt-info-report__inner-wrapper"
    >
      <IconClose
        class="bt-info-report__icon-close"
        @click="closeModal()"
      />
      <div class="bt-info-report__subtitle">
        <span>{{ store.infoReport.title }}</span>
      </div>
      <div class="bt-info-report__header">
        <div
          v-for="item in filterList"
          :key="item"
          class="bt-info-report__wrapper"
        >
          <span class="bt-info-report__title">{{ item.title }}</span>
          <span
            class="bt-info-report__status"
            :class="'bt-info-report_' + item.customClass"
            @click="item.func"
          >
            <span v-if="!item.isInput">{{ item.elem }}</span>
            <input
              v-if="item.isInput"
              v-model="valueReward"
              placeholder="0"
              type="number"
              class="bt-info-report__input-reward"
            >
            <button
              v-if="item.isInput"
              class="bt-info-report__reward-btn"
              :class="valueReward > 0 ? 'bt-info-report_active-btn' : ''"
              @click="sendReward"
            >Accrue</button>
            <component
              :is="item.icon"
              class="bt-info-report__icon-copy"
            />
            <div class="fm-copy__text bt-info-report__copy">
              <span>Click to copy</span>
            </div>
            <div
              v-if="statusList && item.isToggle"
              class="bt-info-report__toggle"
            >
              <div
                v-for="(selectedStatus, index) in listStatus"
                :key="index"
                :class="!selectedStatus.includes(item.elem) ? 'bt-info-report__toggle-items bt-info-report_' + selectedStatus : ''"
                :style="{pointerEvents : store.infoReport.status === selectedStatus ? 'none' : ''}"
                @click="store.getLoadReport(selectedStatus)"
              >
                <span v-if="!selectedStatus.includes(item.elem)">{{ selectedStatus }}</span>
              </div>
            </div>
          </span>
        </div>
      </div>
      <div class="bt-info-report__content">
        <div :class="{'bt-info-report__body scroll' : contentScroll}">
          <p>{{ store.infoReport.body }}</p>
        </div>
        <div
          class="bt-info-report__image-list scroll"
          :style="{marginTop : store.infoReport.files ? '1vw' : ''}"
        >
          <div
            v-for="file in imgList"
            :key="file"
            class="bt-info-report__image-content"
          >
            <div class="bt-info-report__file">
              <img
                class="bt-info-report__image"
                :src="file.link"
                @click="isOpen = true, fileSrc = file.link"
              >
            </div>
            <span>{{ file.name.slice(0, 11) + '...' }}</span>
          </div>
          <OpenMediaFile
            v-if="isOpen"
            :is-open="isOpen"
            :file-src="fileSrc"
            @update:isOpen="isOpen = $event"
          />
        </div>
      </div>
      <div
        v-if="store.getCommentUser.length"
        class="scroll"
        :class="{'bt-info-report__denet-comment' : store.getCommentUser.length }"
      >
        <div class="bt-info-report__comment-created">
          <IconDenet
            class="bt-info-report__icon-denet"
          />
          <span>DeNet team</span>
        </div>
        <div
          v-for="(getCommentUser, index) in store.getCommentUser"
          :key="index"
          class="bt-info-report__items"
        >
          <div class="bt-info-report__text">
            <span>{{ getCommentUser.message }}</span>
            <div class="bt-info-report__date">
              <span>{{ shortenData(getCommentUser.created_at) }}</span>
            </div>
          </div>
          <div
            v-if="$route.name === 'adminId'"
            @click="store.deleteComment(getCommentUser.id)"
          >
            <IconClose class="bt-info-report__icon-delete" />
          </div>
        </div>
      </div>
      <Control />
    </div>
  </div>
</template>
<script>
import Control from '~/components/parts/Control.vue'
import OpenMediaFile from '~/components/parts/OpenMediaFile.vue'
import { shortenAddress, copyText } from '~/helpers/common'
import { shortenData } from '~/helpers/utils'
import { IconClose, IconDenet, IconCopy, IconRedmine, IconArrow } from '~/components/icons/index'
import { useStore } from '~/store'
export default {
  components: {
    IconClose,
    Control,
    IconDenet,
    OpenMediaFile,
    IconCopy,
    IconRedmine,
    IconArrow
  },
  setup () {
    const store = useStore()
    return { store }
  },
  data () {
    return {
      contentScroll: false,
      isOpen: false,
      fileSrc: '',
      statusList: false,
      listStatus: ['pending', 'approved', 'rejected'],
      valueReward: ''
    }
  },
  computed: {
    buttonActive () {
      switch (this.store.infoReport.status) {
        case ('pending'): return 'pending'
        case ('rewarded'): return 'rewarded'
        case ('approved'): return 'approved'
        case ('rejected'): return 'rejected'
        default: return 'pending'
      }
    },
    userItemObj () {
      return [
        { title: 'Sent By: ', elem: this.shortenAddress(this.store.infoReport.sender), customClass: 'account', func: ($event) => this.copyText($event, this.store.infoReport.sender), icon: IconCopy },
        { title: 'Status: ', elem: this.store.infoReport.status, customClass: this.buttonActive },
        { title: 'App : ', elem: this.store.renameApp(this.store.infoReport.app) },
        { title: 'OS: ', elem: '-' },
        { title: 'Reward: ', elem: this.getReward, customClass: 'background' },
        { title: 'Created: ', elem: this.shortenData(this.store.infoReport.created_at) }
      ]
    },
    adminItemObj () {
      return [
        { title: 'Sent By: ', elem: this.shortenAddress(this.store.infoReport.sender), customClass: 'account', func: ($event) => this.copyText($event, this.store.infoReport.sender), icon: IconCopy },
        { title: 'Status: ', elem: this.store.infoReport.status, customClass: this.buttonActive, func: ($event) => this.selectStatus($event, 'titleStatus'), icon: IconArrow, isToggle: true },
        { title: 'Reward: ', elem: '0', isInput: true },
        { title: 'OS: ', elem: '-' },
        { title: 'App : ', elem: this.store.renameApp(this.store.infoReport.app) },
        { title: 'Severity: ', elem: '-' },
        { title: 'Deadline: ', elem: '-' },
        { title: 'Priority: ', elem: '-' },
        { title: 'Readmine: ', elem: 'Add to Redmine', customClass: 'background', icon: IconRedmine },
        { title: 'Created: ', elem: this.shortenData(this.store.infoReport.created_at) }
      ]
    },
    filterList () {
      return this.$route.name !== 'adminId' ? this.userItemObj : this.adminItemObj
    },
    getReward () {
      return this.store.infoReport.reward ? this.store.infoReport.reward + ' TBY' : '0 TBY'
    },
    imgList () {
      const files = []
      for (let i = 0; i < this.store.fileSrcList.length; i++) {
        const file = {
          link: this.store.fileSrcList[i],
          name: this.store.infoReport.files[i]
        }
        files.push(file)
      }
      return files
    }
  },
  async mounted () {
    const paramId = this.$route.params.id
    await this.store.getOneReport(paramId)
    if (this.store.infoReport.files) {
      await Promise.all(this.store.infoReport.files.map((filename) => this.store.fileDownload(filename)))
    }
    this.store.getComment()
    this.$nextTick(() => {
      const $this = this
      if ($this.$refs.contentModal && $this.$refs.contentModal.offsetHeight > 560) {
        $this.contentScroll = true
      }
    })
    document.addEventListener('keydown', function (e) {
      if (e.key === 'Escape') {
        this.closeModal()
      }
    })
  },
  methods: {
    shortenData,
    shortenAddress,
    copyText,
    closeModal () {
      const destination = this.$route.name === 'userId' ? '/' : '/admin'
      this.$router.push(destination)
    },
    selectStatus ($event) {
      this.statusList = !this.statusList
    },
    sendReward () {
      if (this.valueReward !== '') {
        this.store.sentReward(this.valueReward)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~/assets/scss/parts/InfoReportModal.scss";
</style>
