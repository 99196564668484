<template>
  <div class="filter-reports">
    <div class="filter-reports__btn-control">
      <div
        class="filter-reports__tab-btn"
        :class="{'filter-reports_active-btn' : store.formObj.type === 'bug'}"
        @click="selectReport('bug')"
      >
        <span>BUGS</span>
        <div
          class="filter-reports__animation"
          :style="{ background : store.formObj.type === 'suggestion' ? '#ffffff' : '', width : store.formObj.type === 'suggestion' ? '0' : ''}"
        />
      </div>
      <div
        class="filter-reports__tab-btn filter-reports__btn-default"
        :class="{'filter-reports_active-btn' : store.formObj.type === 'suggestion'}"
        @click="selectReport('suggestion')"
      >
        <span>SUGGESTIONS</span>
        <div
          class="filter-reports__animation filter-reports__suggestion-animation"
          :style="{ background : store.formObj.type === 'bug' ? '#ffffff' : '', width : store.formObj.type === 'bug' ? '0' : ''}"
        />
      </div>
    </div>
    <div class="filter-reports__item">
      <span class="filter-reports__count-list">Reports count: {{ store.countReports }}</span>
      <button
        class="filter-reports__btn"
        @click="openReportModal()"
      >
        <div class="filter-reports__plus" />
        <span v-if="store.formObj.type === 'bug'">NEW BUG</span>
        <span v-else>NEW SUGGESTION</span>
      </button>
      <div class="filter-reports__list">
        <div
          v-for="(tab, index) in filterList"
          :key="index"
        >
          <div
            v-if="tab.isAdmin"
            class="filter-reports__tab"
            :class="tab.isToggle ? 'filter-reports_active-tab' : ''"
            @click="tab.isToggle =! tab.isToggle"
          >
            <span>{{ tab.title }}</span>
            <IconArrow
              class="filter-reports__icon-arrow"
              :class="tab.isToggle ? 'filter-reports_icon-active' : ''"
            />
          </div>
          <ul
            class="filter-reports__check-list"
            :class="!tab.isToggle ? 'filter-reports_collapse' : ''"
          >
            <li
              v-for="(item, idx) in tab.content"
              :key="idx"
              class="filter-reports__list-item"
            >
              <input
                :id="'item-' + item.id"
                class="filter-reports__check-input"
                type="checkbox"
                @click="handleCheckboxClick(item)"
              >
              <label
                :for="'item-' + item.id"
              >{{ item.title }}
                <span
                  v-if="tab.title === 'Apps'"
                  class="filter-reports__count"
                >{{ store.titleCount(item.title) }}</span>
                <span
                  v-else
                  class="filter-reports__count"
                >{{ store.statusCount(item.title) }}</span>
              </label>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { IconArrow } from '~/components/icons'
import { useStore } from '../../store'
export default ({
  components: { IconArrow },
  setup () {
    const store = useStore()
    return { store }
  },
  data () {
    return {
      tabs: [
        {
          title: 'Apps',
          isToggle: false,
          isAdmin: true,
          content: [
            { title: 'Storage', checked: false, id: 1, type: 'app' },
            { title: 'Storage Android', checked: false, id: 2, type: 'app' },
            { title: 'Storage IOS', checked: false, id: 3, type: 'app' },
            { title: 'Market', checked: false, id: 4, type: 'app' },
            { title: 'Pass', checked: false, id: 5, type: 'app' },
            { title: 'Node', checked: false, id: 6, type: 'app' },
            { title: 'Desk', checked: false, id: 7, type: 'app' },
            { title: 'NFT Storage', checked: false, id: 8, type: 'app' },
            { title: 'Chat', checked: false, id: 9, type: 'app' },
            { title: 'Payments', checked: false, id: 10, type: 'app' },
            { title: 'Bugtracker', checked: false, id: 11, type: 'app' },
            { title: 'Store&Earn Web Version', checked: false, id: 12, type: 'app' }
          ]
        },
        {
          title: 'Status',
          isToggle: false,
          isAdmin: true,
          content: [
            { title: 'Pending', checked: false, id: 12, type: 'status' },
            { title: 'Rejected', checked: false, id: 13, type: 'status' },
            { title: 'Approved', checked: false, id: 14, type: 'status' },
            { title: 'Rewarded', checked: false, id: 15, type: 'status' }
          ]
        },
        {
          title: 'Severity',
          isToggle: false,
          isAdmin: true,
          content: [
            { title: 'Critical', checked: false, id: 16 },
            { title: 'High', checked: false, id: 17 },
            { title: 'Medium', checked: false, id: 18 },
            { title: 'Low', checked: false, id: 19 }
          ]
        }
      ]
    }
  },
  computed: {
    filterList () {
      if (this.$route.name !== 'Admin') {
        return this.tabs.filter((tab, index) => {
          return index !== 2 || (index === 2 && !tab.isAdmin)
        })
      } else {
        return this.tabs
      }
    }
  },
  methods: {
    selectReport (item) {
      this.store.formObj.type = item
    },
    openReportModal () {
      this.store.reportModal = true
    },
    handleCheckboxClick (item) {
      const targetArray = item.type === 'app' ? this.store.filteredApps : this.store.filteredStatus
      const index = targetArray.indexOf(item.title)
      if (index !== -1) {
        targetArray.splice(index, 1)
      } else {
        targetArray.push(item.title)
      }
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~/assets/scss/parts/FilterReports.scss'
</style>
