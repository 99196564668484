<template>
  <div class="selection-app">
    <div class="selection-app__button">
      <span>{{ store.formObj.app }}</span>
      <IconChevronDown
        class="selection-app__icon"
      />
    </div>
    <div
      v-if="isActive"
      class="selection-app__select"
    >
      <div class="select-app">
        <div
          v-for="selectApp in appList"
          :key="selectApp"
          class="select-app__item"
          @click="selectFunc(selectApp)"
        >
          <span>{{ selectApp }}</span>
        </div>
      </div>
    </div>
    <InputAlerts
      v-if="store.alertObj.selectionAppAlert"
      :item-type="'selectionAppAlert'"
    />
  </div>
</template>
<script>
import { IconChevronDown } from '~/components/icons/index'
import InputAlerts from '~/components/parts/InputAlerts.vue'
import { useStore } from '~/store'
export default {
  name: 'SelectionApp',
  components: {
    IconChevronDown,
    InputAlerts
  },
  setup () {
    const store = useStore()
    return { store }
  },
  data () {
    return {
      isActive: false,
      appList: ['Storage', 'Storage Android', 'Storage IOS', 'Market', 'Pass', 'Node', 'Desk', 'NFT Storage', 'Chat', 'Payments', 'Bugtracker', 'Store&Earn Web Version']
    }
  },
  mounted () {
    const $this = this
    this.$nextTick(() => {
      document.body.addEventListener('click', function (e) {
        const arrayClass = ['selection-app']
        if (!arrayClass.includes(e.target.classList.value)) {
          $this.isActive = false
        } else {
          $this.isActive = !$this.isActive
        }
      })
    })
  },
  methods: {
    selectFunc (selectApp) {
      this.isActive = !this.isActive
      this.store.formObj.app = selectApp
      this.store.alertObj.selectionAppAlert = false
    }
  }
}
</script>
<style scoped lang="scss">
@import "~/assets/scss/parts/SelectionApp.scss";
</style>
