import axios from 'axios'

function apiRequest (obj) {
  if (!obj.header) {
    obj.header = {}
    obj.header['Content-Type'] = 'application/json'
  }
  if (obj.sessionToken) { obj.header.Authorization = `Bearer ${obj.sessionToken}` }
  return axios({
    method: obj.method,
    url: obj.url,
    data: obj.data,
    responseType: obj.responseType,
    headers: obj.header
  })
}

function shortenTitle (title, st) {
  let startPoint
  if (st) startPoint = st
  else startPoint = 25
  if (title && title.length >= 25) return title.slice(0, startPoint) + '...'
  else return title
}

function shortenTitleForModal (title, st) {
  let startPoint
  if (st) startPoint = st
  else startPoint = 20
  if (title && title.length >= 20) return title.slice(0, startPoint) + '...'
  else return title
}

function shortenData (title) {
  if (title && title.length >= 10) return title.slice(8, 10) + '/' + title.slice(5, 7) + '/' + title.slice(0, 4)
  else return title
}

export {
  apiRequest,
  shortenData,
  shortenTitleForModal,
  shortenTitle
}
