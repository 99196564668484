<template>
  <div
    v-if="store.countReports !== 0"
    class="bt-reports"
  >
    <div
      class="bt-reports__main"
      :style="{color : $route.name === 'Admin' ? '#676767' : ''}"
    >
      <div :class="activeClass">
        <span v-if="$route.name === 'Admin'">Sender</span>
        <span v-else>#</span>
      </div>
      <div class="bt-reports__name">
        <div class="bt-reports__title">
          <span v-if="store.formObj.type === 'bug'">Bug</span>
          <span v-else>Suggestion</span>
        </div>
      </div>
      <div
        :class="activeAppDate"
        :style="{cursor: 'default'}"
      >
        <span>App</span>
      </div>
      <div class="bt-reports__status">
        <span>Status</span>
      </div>
      <div
        v-if="$route.name === 'Admin'"
        :class="activeAppDate"
      >
        <div class="bt-reports__title">
          <span>Severity</span>
        </div>
      </div>
      <div :class="activeAppDate">
        <div class="bt-reports__title">
          <span
            class="bt-reports__sorting"
            :class="{'bt-reports__sort-by-date' : isToggle}"
            @click="sortByDateFunc"
          >Created
            <IconArrow
              class="bt-reports__icon-arrow"
              :style="{transition : isToggle ? 'all .3s linear' : '', transform : isToggle ? 'rotate(180deg)' : ''}"
            />
          </span>
          <div
            class="bt-reports__created-animation"
            :style="{ background : isToggle ? '#ffd600' : '', width : isToggle ? '100%' : ''}"
          />
        </div>
      </div>
    </div>
    <div class="bt-reports__list">
      <div
        v-for="(bugTrackerInfo, index) in store.reportList"
        :key="index"
        class="bt-reports__content"
        @click="infoReportModal(bugTrackerInfo)"
      >
        <div class="bt-reports__item">
          <div :class="activeClass">
            <span v-if="$route.name === 'Admin'">{{ shortenAddress(bugTrackerInfo.sender) }}</span>
            <span v-else>#{{ bugTrackerInfo.num }}</span>
          </div>
          <div class="bt-reports__name bt-reports_dark">
            <span>{{ shortenTitle(bugTrackerInfo.title) }}</span>
          </div>
          <div :class="activeAppDate">
            <span style="text-transform: capitalize;">{{ store.renameApp(bugTrackerInfo.app) }}</span>
          </div>
          <div class="bt-reports__status">
            <button
              :class="'bt-reports_' + bugTrackerInfo.status"
              class="bt-reports__button-status"
            >
              {{ bugTrackerInfo.status }}
              <span v-if="bugTrackerInfo.status === 'rewarded'">{{ bugTrackerInfo.reward }} TBY</span>
            </button>
          </div>
          <div
            v-if="$route.name === 'Admin'"
            :class="activeAppDate"
          >
            <span>not set</span>
          </div>
          <div :class="activeAppDate">
            <span>{{ shortenData(bugTrackerInfo.created_at) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-else
    class="bt-reports__list"
  >
    <EmptyReport :item-type="store.formObj.type" />
  </div>
</template>
<script>
import EmptyReport from '~/components/parts/EmptyReport.vue'
import { IconArrow } from '~/components/icons/index'
import { shortenTitle, shortenData } from '~/helpers/utils'
import { shortenAddress } from '~/helpers/common'
import { useStore } from '../../store/index'
export default {
  name: 'BugsList',
  components: { IconArrow, EmptyReport },
  data () {
    return {
      isToggle: false
    }
  },
  setup () {
    const store = useStore()
    return { store }
  },
  computed: {
    activeClass () {
      return this.$route.name === 'Admin' ? 'bt-reports_nomer-address bt-reports_grey' : 'bt-reports_nomer bt-reports_grey'
    },
    activeAppDate () {
      return this.$route.name === 'Admin' ? 'bt-reports_app-date-admin' : 'bt-reports__app-date'
    }
  },
  methods: {
    shortenAddress,
    shortenTitle,
    shortenData,
    infoReportModal (bugTrackerInfo) {
      const user = this.$route.name.toLowerCase() === 'user' ? '' : '/admin'
      this.$router.push(`${user}/${bugTrackerInfo.type}/${bugTrackerInfo.id}`)
      this.store.infoReport = bugTrackerInfo
      this.store.fileSrcList = []
    },
    sortByDateFunc () {
      this.isToggle = !this.isToggle
      this.store.bugTrackerReportsList.sort((a, b) => this.isToggle ? new Date(a.created_at) - new Date(b.created_at) : new Date(b.created_at) - new Date(a.created_at))
    }
  }
}
</script>
<style scoped lang="scss">
@import "~/assets/scss/parts/ReportList.scss";
</style>
