<template>
  <header>
    <div class="bt-header">
      <div class="bt-header__logo">
        <LogoBugTracker />
        <a
          href="https://denet.pro/"
          target="_blank"
          class="bt-header__image"
        >
          <IconLogoDeNet class="bt-header__denet-logo" />
        </a>
      </div>
      <div class="bt-header__user-menu">
        <div class="bt-header__center">
          <input
            ref="inputSearch"
            v-model="reportValue"
            :placeholder="'Search ' + store.formObj.type"
            type="text"
            class="bt-header__input-search"
            @keyup="store.searchReport(reportValue)"
          >
          <IconSearch class="bt-header__icon" />
        </div>
        <div :class="store.isAdminStatus === 200 ? 'bt-header__right' : 'bt-header_right-user'">
          <div
            :style="{ backgroundColor: isToggle ? '#EBEBEB' : '' }"
            class="bt-header__menu-item bt-header_question"
            @click="isToggle = !isToggle"
          >
            <span>?</span>
          </div>
          <div class="bt-header__item">
            <span>Reward: 0 TBY</span>
          </div>
          <div
            v-if="store.isAdminStatus === 200"
            class="bt-header__item bt-header_switch"
          >
            <span
              class="bt-header__item-title"
              :class="$route.name === 'Admin' ? 'bt-header_active' : ''"
              @click="changeRoute('/admin')"
            >Admin</span>
            <span
              :class="$route.name === 'User' ? 'bt-header_active' : ''"
              class="bt-header__item-title"
              @click="changeRoute('/')"
            >User</span>
          </div>
          <div
            class="bt-header__item"
            :style="{ backgroundColor: clickAddress ? '#EBEBEB' : '' }"
            @click="copyText($event, store.address); clickAddress = true"
            @mouseleave="clickAddress = false"
          >
            <div class="fm-copy__text bt-header__copy">
              <span>Click to copy</span>
            </div>
            <span>{{ shortenAddress(store.address) }}</span>
          </div>
          <div
            class="bt-header__menu-item"
            @click="store.logout"
          >
            <IconLogout class="bt-header__icon-logout" />
          </div>
          <div
            v-if="isToggle"
            class="bt-header__reward-details"
          >
            <div class="bt-header__header">
              <span class="bt-header__subtitle">Reward details</span>
              <IconClose
                class="bt-header__icon-close"
                :color="'#ACADB5'"
                @click="isToggle = !isToggle"
              />
            </div>
            <div class="bt-header__text">
              <div class="bt-header__title">
                <span>You can report a bug or send a suggestion to DeNet Developers Team. On the team consideration DeNet can reward you, if the bug or a suggestion will be the first in it's kind.</span>
              </div>
              <span>The reward is represented in decentralized storage capacity up to 10 GB. Using it you can stake your NFTs and store any other files in DeNet Ecosystem for free.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import { IconClose, IconLogout, IconSearch, IconLogoDeNet } from '~/components/icons/index'
import LogoBugTracker from '~/components/icons/LogoBugTracker.vue'
import { shortenAddress, copyText } from '~/helpers/common'
import { useStore } from '~/store'

export default {
  name: 'PageHeader',
  components: {
    IconClose,
    IconLogout,
    LogoBugTracker,
    IconSearch,
    IconLogoDeNet
  },
  setup () {
    const store = useStore()
    return { store }
  },
  data () {
    return {
      isToggle: false,
      clickAddress: false
    }
  },
  mounted () {
    const $this = this
    document.addEventListener('keydown', (e) => {
      if (e.ctrlKey && e.key === 'f' && $this.$refs.inputSearch) {
        e.preventDefault()
        $this.$refs.inputSearch.focus()
      }
    })
  },
  methods: {
    copyText,
    shortenAddress,
    changeRoute (name) {
      this.$router.push(name)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~/assets/scss/parts/Header.scss";
</style>
