import ConnectWallet from '~/components/ConnectWallet.vue'
import InfoReportModal from '~/components/parts/InfoReportModal'
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'User',
    component: ConnectWallet
  },
  {
    path: '/admin',
    name: 'Admin',
    component: ConnectWallet
  },
  {
    path: '/admin/:type/:id',
    name: 'adminId',
    component: InfoReportModal
  },
  {
    path: '/:type/:id',
    name: 'userId',
    component: InfoReportModal
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
