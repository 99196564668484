<template>
  <form
    v-if="store.reportModal"
    class="form-report"
    @submit.prevent="postData"
  >
    <div class="form-report__wrapper">
      <div class="form-report__header">
        <span v-if="store.formObj.type === 'bug'">Bug information</span>
        <span v-else>Suggestion information</span>
        <IconClose
          class="form-report__icon-close"
          @click="reportModalClose"
        />
      </div>
      <div class="form-report__wrap">
        <div class="form-report__item">
          <span class="form-report__subtitle">Title</span>
          <div class="form-report__content">
            <input
              ref="Subject"
              v-model="store.formObj.title"
              class="form-report__subject-area"
              :style="{border : borderInput === false ? 'solid 1px #ACADB5' : ''}"
              type="text"
              placeholder="Subject"
              maxlength="100"
              @keyup="inputTitle"
              @focusin="inputTitle"
            >
            <InputAlerts
              v-if="store.alertObj.subjectAlert"
              :item-type="'subjectAlert'"
            />
          </div>
        </div>
        <div class="form-report__app-selection">
          <span class="form-report__subtitle">App</span>
          <SelectionApp
            v-model="app"
          />
        </div>
      </div>
      <span class="form-report__subtitle">Description</span>
      <div class="form-report__content">
        <textarea
          ref="Content"
          v-model="formattedQuestions"
          class="form-report__text-area scroll"
          :style="{border : borderInput ? 'solid 1px #ACADB5' : ''}"
          maxlength="1000"
          @keyup="textArea"
          @paste="pasteImage"
          @focusin="textArea"
        />
        <InputAlerts
          v-if="store.alertObj.contentAlert"
          :item-type="'contentAlert'"
        />
      </div>
      <div
        v-if="store.formObj.type === 'bug'"
        class="form-report__character"
      >
        <div class="form-report__character-input">
          <span class="form-report__subtitle">App version (Optional)</span>
          <input
            ref="appVersion"
            v-model="store.formObj.appVersion"
            class="form-report__subject-area"
            :style="{border : borderInput === false ? 'solid 1px #ACADB5' : ''}"
            type="text"
            placeholder="Format: v1.1.1"
            maxlength="20"
          >
        </div>
        <div class="form-report__character-input">
          <span class="form-report__subtitle">OS (Optional)</span>
          <input
            ref="characterOs"
            v-model="store.formObj.os"
            class="form-report__subject-area"
            :style="{border : borderInput === false ? 'solid 1px #ACADB5' : ''}"
            type="text"
            placeholder="Operating System"
            maxlength="30"
          >
          <InputAlerts
            v-if="store.alertObj.ocAlert"
            :item-type="'ocAlert'"
          />
        </div>
      </div>
      <div class="form-report__image-list scroll">
        <div
          v-for="(item, index) in inputFile"
          :key="item"
          class="form-report__download-image"
        >
          <span>{{ item.name.slice(0, 40) }}</span>
          <IconClose
            class="form-report__cancel-image"
            @click="cancelUpload(index)"
          />
        </div>
      </div>
      <div class="form-report__submit">
        <button
          type="submit"
          class="form-report__button-send"
          :style="{pointerEvents : iconLoader ? 'none' : ''}"
        >
          <IconLoader v-if="iconLoader" />
          <span v-else>SEND</span>
        </button>
        <label class="form-report__file-upload">
          <input
            ref="filesSelection"
            type="file"
            multiple
            class="form-report__file-input"
            accept="image/*"
            @change="handleFileUpload()"
          >
          <IconUpload class="form-report__icon-upload" />
        </label>
        <InputAlerts
          v-if="inputAlertForfiles"
          :item-type="'InputAlertForfiles'"
        />
      </div>
    </div>
  </form>
</template>
<script>
import IconLoader from '~/components/icons/IconLoader.vue'
import SelectionApp from '~/components/parts/SelectionApp.vue'
import InputAlerts from '~/components/parts/InputAlerts.vue'
import IconClose from '~/components/icons/IconClose.vue'
import { IconUpload } from '~/components/icons/index'
import { useStore } from '~/store'
export default {
  components: {
    IconClose,
    SelectionApp,
    InputAlerts,
    IconLoader,
    IconUpload
  },
  setup () {
    const store = useStore()
    return { store }
  },
  data () {
    return {
      questions: 'What did you do?What did you expect to see?What did you see instead?',
      iconLoader: false,
      inputAlertForfiles: '',
      borderInput: '',
      inputFile: []
    }
  },
  computed: {
    formattedQuestions () {
      return this.store.formObj.type === 'bug' ? this.questions.replace(/\?/g, '?\n\n').replace(/ /g, '\xa0') : ''
    }
  },
  methods: {
    pasteImage (e) {
      var item = Array.from(e.clipboardData.items).find(x => /^image\//.test(x.type))
      var blob = item.getAsFile()
      this.inputFile.push(blob)
      this.store.formObj.files = this.inputFile
    },
    cancelUpload (index) {
      const fileListArr = Array.from(this.inputFile)
      fileListArr.splice(index, 1)
      this.inputFile = fileListArr
    },
    handleFileUpload () {
      const files = Array.from(this.$refs.filesSelection.files)
      const totalSize = files.reduce((acc, file) => acc + file.size, 0)
      if (totalSize > 64 * 1024 * 1024) {
        this.inputAlertForfiles = true
        this.inputFile = []
      } else {
        this.inputAlertForfiles = false
        if (!files.every(f => this.inputFile.includes(f))) {
          this.inputFile.push(...files)
        }
      }
    },
    postData (e) {
      const { title, body, app } = this.store.formObj
      if (title.trim() && body.trim() && app !== 'Select') {
        this.iconLoader = true
        this.store.sendReport()
        e.preventDefault()
      } else {
        this.store.alertObj.subjectAlert = !title.trim()
        this.store.alertObj.contentAlert = !body.trim()
        this.store.alertObj.selectionAppAlert = app === 'Select'
      }
    },
    inputTitle () {
      this.borderInput = false
      this.store.formObj.title = this.$refs.Subject.value
      if (this.store.formObj.title !== '') {
        this.store.alertObj.subjectAlert = false
      }
    },
    textArea () {
      this.borderInput = true
      this.store.formObj.body = this.$refs.Content.value
      if (this.store.formObj.body !== '') {
        this.store.alertObj.contentAlert = false
      }
    },
    reportModalClose () {
      this.store.reportModal = false
      this.store.alertObj.selectionAppAlert = false
      this.store.alertObj.contentAlert = false
      this.store.alertObj.subjectAlert = false
      this.inputFile = []
      this.store.formObj.os = ''
      this.store.formObj.appVersion = ''
      this.store.formObj.title = ''
      this.store.formObj.app = 'Select'
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~/assets/scss/parts/FormReport.scss";
</style>
