<template>
  <div
    v-for="item in itemObj[itemType]"
    :key="item"
    class="bt-empty-report"
  >
    <img
      :src="require(`../../assets/images/${item.image}`)"
      :alt="itemType + 'empty img'"
      class="bt-empty-report__image"
    >
    <span class="bt-empty-report__text">{{ item.text }}</span>
    <button
      class="bt-empty-report__btn"
      @click="store.reportModal = true"
    >
      <div class="bt-empty-report__plus" />
      <span>new {{ itemType }}</span>
    </button>
  </div>
</template>

<script>
import { useStore } from '~/store'
export default ({
  name: 'EmptyReport',
  props: ['itemType'],
  setup () {
    const store = useStore()
    return { store }
  },
  data () {
    return {
      itemObj: {
        bug: [
          { image: 'img-bug.png', text: 'Report about a bug or an error and receive a reward. Help us to improve DeNet ecosystem!' }
        ],
        suggestion: [
          { image: 'img-suggestion.png', text: 'Have an idea how to improve DeNet ecosystem? Send it to us and get a reward.' }
        ]
      }
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~/assets/scss/parts/EmptyReport.scss'
</style>
